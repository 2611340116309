import React from "react";
import { HashLink } from "react-router-hash-link";
import "./Dropdown.css";

function Dropdown() {
  return (
    <ul className="dropdown-menu">
      <li className="dropdown-item">
        <HashLink to="/admin-docs#section1" className="dropdown-link">
          Rotaract District Administrative Policy
        </HashLink>
      </li>
      <li className="dropdown-item">
        <HashLink to="/admin-docs#section2" className="dropdown-link">
          Rotary Code of Policy
        </HashLink>
      </li>
      <li className="dropdown-item">
        <HashLink to="/admin-docs#section3" className="dropdown-link">
          Standard Rotaract Club Constitution
        </HashLink>
      </li>
      <li className="dropdown-item">
        <HashLink to="/admin-docs#section4" className="dropdown-link">
          Recommended Rotaract Club By Laws
        </HashLink>
      </li>
    </ul>
  );
}

export default Dropdown;
