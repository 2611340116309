import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Dropdown from "./Dropdown";
import DistDirDrop from "./DistDirDrop";
import GuidelineDrop from "./GuidelineDrop";
import PlanningDocsDrop from "./PlanningDocsDrop";

function NavBar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      console.log("Mouse Enter - Show Dropdown");
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      console.log("Mouse Leave - Hide Dropdown");
      setDropdown(false);
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src="/images/RGB - White.png" alt="rota" width={250} />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li
              className="nav-item nav-admin"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link
                to="/admin-docs"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Admin Docs <i className="fas fa-caret-down" />
              </Link>
              {dropdown && <Dropdown />}
            </li>
            <li
              className="nav-item nav-admin"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link
                to="https://docs.google.com/spreadsheets/d/1x0WfYA3N7IzA26KFz0AdcB7EbbQkNhWuLTF1dMBPmN0/edit?gid=929871893#gid=929871893"
                className="nav-links"
                onClick={closeMobileMenu}
                target="_blank"
              >
                District Directory
                <i className="fas fa-caret-down" />
              </Link>
              {dropdown && <DistDirDrop />}
            </li>
            <li
              className="nav-item nav-admin"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link
                to="/guidelines"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Guidelines & Protocols
                <i className="fas fa-caret-down" />
              </Link>
              {dropdown && <GuidelineDrop />}
            </li>
            <li
              className="nav-item nav-admin"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link
                to="/planning-docs"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Planning Docs
                <i className="fas fa-caret-down" />
              </Link>
              {dropdown && <PlanningDocsDrop />}
            </li>
            <li
              className="nav-item nav-admin"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link to="/drr" className="nav-links" onClick={closeMobileMenu}>
                DRR
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
