import React from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HandBook from "./docs/handbook";
import Citation from "./docs/citation";
import YearPlan from "./docs/yearPlan";

export default function AdminDocs() {
  return (
    <>
      {/* <h1 className="services">Admin Docs</h1> */}
      <section id="section1">
        <center>
          <img src="/images/handbook.png" width="90%" id="one" />
        </center>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 my-3">
              <div className="card p-4">
                <HandBook />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section2">
        <center>
          <img src="/images/citation.png" width="90%" />
        </center>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 my-3">
              <div className="card p-4">
                <Citation />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section3">
        <center>
          <img src="/images/yearPlan.png" width="90%" />
        </center>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 my-3">
              <div className="card p-4">
                <YearPlan />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
