import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Card, CardMedia } from "@mui/material";

function RotaryCode() {
  return (
    <div>
      <Container fluid className="resume-section">
        <Row className="resume">
          <Card sx={{ maxWidth: "100%" }}>
            <CardMedia
              className="cardmedia"
              component="iframe"
              Height="700px"
              // src="/manifesto.pdf"
              src="https://drive.google.com/file/d/1odUnfD65dRWicJFqcVM1XfuH1wWAuDRu/preview"
            />
          </Card>
        </Row>
      </Container>
    </div>
  );
}

export default RotaryCode;
