import React from "react";
import { HashLink } from "react-router-hash-link";
import "./DistDirDrop.css";

function PlanningDocsDrop() {
  return (
    <ul className="dropdown-menu">
      <li className="dropdown-item">
        <HashLink to="planning-docs#section1" className="dropdown-link">
          Rotaract District Handbook
        </HashLink>
      </li>
      <li className="dropdown-item">
        <HashLink to="planning-docs#section2" className="dropdown-link">
          Rotaract District Citation
        </HashLink>
      </li>
      <li className="dropdown-item">
        <HashLink to="planning-docs#section3" className="dropdown-link">
          District Year Plan
        </HashLink>
      </li>
    </ul>
  );
}

export default PlanningDocsDrop;
