import React from "react";
import "../../App.css";
import AdminPolicy from "./docs/adminPolicy";
import ByLaws from "./docs/rotaByLaws";
import "bootstrap/dist/css/bootstrap.min.css";
import RotaryCode from "./docs/rotaryCode";
import ClubConst from "./docs/clubConst";

export default function AdminDocs() {
  return (
    <>
      {/* <h1 className="services">Admin Docs</h1> */}
      <section id="section1">
        <center>
          <img src="/images/1.png" width="90%" id="one" />
        </center>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 my-3">
              <div className="card p-4">
                <AdminPolicy />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section2">
        <center>
          <img src="/images/2.png" width="90%" />
        </center>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 my-3">
              <div className="card p-4">
                <RotaryCode />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section3">
        <center>
          <img src="/images/3.png" width="90%" />
        </center>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 my-3">
              <div className="card p-4">
                <ClubConst />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section4">
        <center>
          <img src="/images/4.png" width="90%" />
        </center>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 my-3">
              <div className="card p-4">
                <ByLaws />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
