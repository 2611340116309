import React from "react";
import NavBar from "./components/Navbar";
import "./App.css";
import AdminDocs from "./components/pages/AdminDocs";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DistrictDirectory from "./components/pages/DistrictDirectory";
import DRR from "./components/pages/DRR";
import Guidelines from "./components/pages/Guidelines";
import PlanningDocs from "./components/pages/PlanningDocs";
import Home from "./components/pages/Home";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/admin-docs" element={<AdminDocs />} />
          <Route path="/district-dir" element={<DistrictDirectory />} />
          <Route path="/guidelines" element={<Guidelines />} />
          <Route path="/planning-docs" element={<PlanningDocs />} />
          <Route path="/drr" element={<DRR />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
