import React from "react";
import { Link } from "react-router-dom";
import "./DistDirDrop.css";

function DistDirDrop() {
  return (
    <ul className="dropdown-menu">
      <li className="dropdown-item">
        <Link
          to="https://docs.google.com/spreadsheets/d/1yIrxLII1Tkd3hlBBNhKAP9MCCrK-AtXxrVT9Gn1lXlM/edit?usp=drive_link"
          className="dropdown-link"
          target="_blank"
        >
          Rotaract Club Directory
        </Link>
      </li>
      <li className="dropdown-item">
        <Link
          to="https://docs.google.com/spreadsheets/d/1HGtVjkf3LKBdREkSLgvFNK4O_7Q7r0fJ67JoGaFBM5Y/edit?usp=drive_link"
          className="dropdown-link"
          target="_blank"
        >
          Rotaract District Steering Committee
        </Link>
      </li>
      <li className="dropdown-item">
        <Link
          to="https://docs.google.com/spreadsheets/d/1N-e33FD3uMlf8dHzTfVqxdbDmtN-3AN2i38UTbRDMfs/edit?usp=drive_link"
          className="dropdown-link"
          target="_blank"
        >
          Guide Club Coordinators
        </Link>
      </li>
      <li className="dropdown-item">
        <Link
          to="https://docs.google.com/spreadsheets/d/1vUMdrGoy6m1IBm04SX_ZqT_03TWTqqSiQ5kD3kwOKyU/edit?usp=drive_link"
          className="dropdown-link"
          target="_blank"
        >
          JDRC
        </Link>
      </li>
      <li className="dropdown-item">
        <Link
          to="https://docs.google.com/spreadsheets/d/11QLNB3siL7WaQOMFFSVRMSGLpV_AG7UPjdobtrgyYq8/edit?usp=drive_link"
          className="dropdown-link"
          target="_blank"
        >
          Zonal Allocations
        </Link>
      </li>
    </ul>
  );
}

export default DistDirDrop;
