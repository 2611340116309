import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import "./Button.css";

function HeroSection() {
  return (
    <div className="hero-container">
      {/* <video src="/videos/video-1.mp4" autoPlay loop muted /> */}
      {/* <img src="/images/img-8.jpg" /> */}
      <h1>WELCOME TO ROTARACT INFORMATION PORTAL</h1>
      <p>ROTARACT IN ROTARY INTERNATIONAL DISTRICT 3220</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          link="https://drive.google.com/drive/folders/1Btuvpgu1rRYzDkhnGHPcuXyrfNuHSBkV?usp=drive_link"
        >
          Brand Center
        </Button>
        {/* <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          link="https://web.facebook.com/rotaract3220"
        >
          Official Facebook Page
          <i className="far fa-play-circle" />
      </Button> */}
      </div>
    </div>
  );
}

export default HeroSection;
