import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards(props) {
  return (
    <div className="cards">
      <center>
        <h1>Check Out These Events</h1>
      </center>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/dots.jpg"
              text="District Officials Training Seminar"
              label="DisCo Event"
              path="https://www.facebook.com/share/p/z7GBZS5SovArt3ai/"
            />
            <CardItem
              src="images/ps.jpg"
              text="PETS & SETS 2024-25"
              label="District Event"
              path="https://www.facebook.com/share/p/NAqmQTVrZNpJ4dGr/"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
