import React from "react";
import { HashLink } from "react-router-hash-link";
import "./DistDirDrop.css";

function GuidelineDrop() {
  return (
    <ul className="dropdown-menu">
      <li className="dropdown-item">
        <HashLink to="guidelines#section1" className="dropdown-link">
          Transition Meeting Protocols
        </HashLink>
      </li>
      <li className="dropdown-item">
        <HashLink to="guidelines#section2" className="dropdown-link">
          Installation Ceremony Protocols
        </HashLink>
      </li>
    </ul>
  );
}

export default GuidelineDrop;
